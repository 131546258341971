import { ref, Ref, unref } from "vue";
import { useQuery } from "@vue/apollo-composable";
import type { ObservableQuery } from "@apollo/client/core/index";
import gql from "graphql-tag";
import { GqlBuilding, MaybeRef } from "@/types";

export interface UseBuildingOptions {
  enabled?: MaybeRef<boolean>;
}

export type UseBuildingQuery = ObservableQuery<any, { buildingUuid: MaybeRef<string | null> }>;

export interface UseBuildingResult {
  building: Ref<GqlBuilding | undefined>;
  query: Ref<UseBuildingQuery | null | undefined>;
  loadingError: Ref<boolean>;
  refetch: () => void;
  loading: Ref<boolean>;
}

export function useBuilding(
  buildingUuid: MaybeRef<string | null>,
  { enabled = true }: UseBuildingOptions = {}
): UseBuildingResult {
  const building = ref<GqlBuilding | undefined>();
  const loadingError = ref(false);

  const { onResult, onError, refetch, loading, query } = useQuery(
    gql`
      query BuildingQuery($buildingUuid: ID!) {
        building(buildingUuid: $buildingUuid) {
          buildingUuid
          accountUuid
          name
          fullAddress
          online
          gpsCoordinates {
            lat
            lng
          }
          timeZone
          floors {
            floorUuid
            position
            name
            floorplan {
              url
              sizeX
              sizeY
            }
          }
        }
      }
    `,
    {
      buildingUuid
    },
    () => ({
      enabled: unref(enabled) && unref(buildingUuid) !== null,
      fetchPolicy: "no-cache",
      notifyOnNetworkStatusChange: true
    })
  );

  onError(() => {
    loadingError.value = true;
  });

  onResult(queryResult => {
    if (queryResult.data) {
      building.value = queryResult.data.building;
    }
  });

  return { building, query, loadingError, refetch, loading };
}

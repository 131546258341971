import gql from "graphql-tag";
import { apolloClient } from "@/plugins/vue-apollo";
import { DeviceConfig } from "@/types";

export default function (config: DeviceConfig): Promise<DeviceConfig> {
  return apolloClient
    .mutate({
      mutation: gql`
        mutation ($configUuid: ID!, $name: String!, $configType: String!, $settings: JSON!) {
          updateDeviceConfig(configUuid: $configUuid, name: $name, configType: $configType, settings: $settings)
        }
      `,
      variables: {
        configUuid: config.configUuid,
        name: config.name,
        configType: config.configType,
        settings: config.settings
      }
    })
    .then(() => {
      return config;
    });
}

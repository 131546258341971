import { computed, unref } from "vue";
import { useSubscription } from "@vue/apollo-composable";
import gql from "graphql-tag";
import {
  DecoratedAsset,
  MaybeRef,
  AssetDataChange,
  StateManagementRequestStart,
  AssetDataChangeResponse
} from "@/types";
import { updateAssetProperties, startStateManagementRequests } from "@/utils/state-management";
import store from "@/store";

export function useAssetSubscription(
  assets: MaybeRef<DecoratedAsset[] | undefined>,
  onDataChange?: (assets: DecoratedAsset[], changes: AssetDataChange[]) => void,
  onStateManagementRequestStart?: (assets: DecoratedAsset[], requests: StateManagementRequestStart[]) => void
): void {
  const assetUuids = computed(() => unref(assets)?.map(a => a.assetUuid) ?? []);
  const accessToken = computed<string | null>(() => store.getters.accessToken);

  const { onResult: onSubscriptionResult } = useSubscription(
    gql`
      subscription AssetDataChangeStateManagementRequestStarts($assetUuids: [ID!]!, $authorization: String!) {
        assetDataChanges(assetUuids: $assetUuids, authorization: $authorization) {
          ... on AssetDataChange {
            assetUuid
            property
            stamp
            value
            deviceLock
            pending
            state
            stateInfo
            updateStamp
            messageStamp
          }
          ... on StateManagementRequestStart {
            assetUuid
            stamp
            operation
            properties
          }
        }
      }
    `,
    {
      assetUuids,
      authorization: accessToken
    },
    () => ({
      enabled: !!accessToken.value && assetUuids.value.length > 0
    })
  );

  onSubscriptionResult(({ data }) => {
    if (!data?.assetDataChanges) return;

    const actualAssets = unref(assets);
    if (!actualAssets) return;

    const dataChanges: { assets: DecoratedAsset[]; changes: AssetDataChange[] } = { assets: [], changes: [] };
    const requestStarts: { assets: DecoratedAsset[]; requests: StateManagementRequestStart[] } = {
      assets: [],
      requests: []
    };

    data.assetDataChanges.forEach((change: AssetDataChangeResponse) => {
      const asset = actualAssets.find(a => a.assetUuid === change.assetUuid);
      if (!asset) return;

      if (change.__typename === "AssetDataChange") {
        dataChanges.assets.push(asset);
        dataChanges.changes.push(change);
      } else if (change.__typename === "StateManagementRequestStart") {
        requestStarts.assets.push(asset);
        requestStarts.requests.push(change);
      }
    });

    if (dataChanges.changes.length > 0) {
      onDataChange
        ? onDataChange(dataChanges.assets, dataChanges.changes)
        : updateAssetProperties(dataChanges.assets, dataChanges.changes);
    }

    if (requestStarts.requests.length > 0) {
      onStateManagementRequestStart
        ? onStateManagementRequestStart(requestStarts.assets, requestStarts.requests)
        : startStateManagementRequests(requestStarts.assets, requestStarts.requests);
    }
  });
}

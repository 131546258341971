import gql from "graphql-tag";
import { apolloClient } from "@/plugins/vue-apollo";
import { DeviceConfig } from "@/types";

export default function (config: Omit<DeviceConfig, "configUuid">): Promise<DeviceConfig> {
  return apolloClient
    .mutate({
      mutation: gql`
        mutation ($name: String!, $configType: String!, $settings: JSON!, $buildingUuid: ID!) {
          createDeviceConfig(name: $name, configType: $configType, settings: $settings, buildingUuid: $buildingUuid)
        }
      `,
      variables: {
        name: config.name,
        configType: config.configType,
        settings: config.settings,
        buildingUuid: config.buildingUuid
      }
    })
    .then(response => {
      const configUuid = response.data.createDeviceConfig;
      return { ...config, configUuid };
    });
}

import gql from "graphql-tag";
import { apolloClient } from "@/plugins/vue-apollo";
import { DocumentUploadDetails } from "@/types";
import store from "@/store";

export default function (files: DocumentUploadDetails[]): Promise<any> {
  return apolloClient
    .mutate({
      mutation: gql`
        mutation ($accountUuid: ID!, $detailsList: [DocumentUploadDetails!]!) {
          newDocuments(accountUuid: $accountUuid, detailsList: $detailsList) {
            message
            stamp
            statusCode
          }
        }
      `,
      variables: {
        accountUuid: store.getters.contextAccountUuid,
        detailsList: files
      },
      context: {
        hasUpload: true
      }
    })
    .then(response => {
      return response.data.newDocument;
    });
}

import { ComputedRef, computed, reactive } from "vue";
import { assign, filter, ListIterateeCustom } from "lodash";
import { DateTime } from "luxon";
import {
  GqlAsset,
  DecoratedAsset,
  PropertyConfig,
  DecoratedProperty,
  ThresholdPair,
  Unit,
  FieldDescriptor,
  ParamProvider,
  UnitOrDefault,
  ChildLink,
  ParentLink,
  PropertySelection,
  PropertySelectionByName
} from "@/types";
import baseAssetConfig from "@/config/base-asset";
import { convertUnitWithFn, round } from "@/utils/number";
import { convertPairsToThresholds } from "@/utils/models";
import { getIndexedField, toDescriptorObject, descriptorMatchesDimensions } from "@/utils/indexed-field";
import { getAssetConfig } from "./known-asset";
import { buildProperty, buildThresholdFields, createProperties, getPropertyConfig } from "@/utils/properties";
import { DEFAULT_UNIT } from "./constants";
import { syncPendingStateManagementRequests } from "@/utils/state-management";

interface GetPropertyOptions {
  buildIfMissing?: boolean;
  unit?: UnitOrDefault;
}

export function destinationUnit(propertyConfig: PropertyConfig): Unit | undefined {
  return propertyConfig.unitSelectorFn?.() ?? propertyConfig.unit;
}

export const formConfig = baseAssetConfig;

export function convertProperty(
  asset: DecoratedAsset,
  property: DecoratedProperty,
  unit: UnitOrDefault | null = DEFAULT_UNIT
): DecoratedProperty {
  if (!unit || unit === property.config.unit) return property;

  const config = getPropertyConfig(asset.config, property.name, unit);
  const { convertValueFn } = config;
  const params = assetParamProvider(asset);
  let { value, thresholdArray } = property;

  if (convertValueFn) {
    value = convertUnitWithFn(value, convertValueFn, params);
    thresholdArray = thresholdArray.map(threshold => ({
      ...threshold,
      compareValue: round(convertUnitWithFn(threshold.compareValue, convertValueFn, params)) ?? 0
    }));
  }

  return {
    ...property,
    value,
    thresholdArray,
    thresholds: convertPairsToThresholds(thresholdArray),
    config
  };
}

export function assetParamProvider(asset: DecoratedAsset): ParamProvider {
  const cache: Record<string, ComputedRef<any>> = {};
  return key => {
    cache[key] ||= computed(() => getOptionalProperty(asset, key)?.value);
    return cache[key].value;
  };
}

export function getProperty(
  asset: DecoratedAsset,
  descriptor: FieldDescriptor,
  options: GetPropertyOptions = {}
): DecoratedProperty {
  return getOptionalProperty(asset, descriptor, { ...options, buildIfMissing: true }) as DecoratedProperty;
}

export function getOptionalProperty(
  asset: DecoratedAsset,
  descriptor: FieldDescriptor,
  { buildIfMissing = false, unit = undefined }: GetPropertyOptions = {}
): DecoratedProperty | undefined {
  const descriptorObj = toDescriptorObject(descriptor);
  const { dimensions, category } = getPropertyConfig(asset.config, descriptorObj.name);

  if (category === "asset") {
    return getBuiltinProperty(asset, descriptorObj.name);
  }

  if (!descriptorMatchesDimensions(descriptorObj, dimensions)) {
    const { name, params } = descriptorObj;
    throw Error(`Incorrect params for property ${name} (${dimensions} needed): [${params}]`);
  }

  let property = getIndexedField(asset.properties, descriptorObj);

  if (!property && buildIfMissing) {
    const thresholdArray = asset.thresholds[descriptorObj.name] ?? [];
    property = buildProperty(asset.config, descriptorObj, {}, thresholdArray);
  }

  if (property && unit) {
    property = convertProperty(asset, property, unit);
  }

  return property;
}

export function getBuiltinProperty(asset: DecoratedAsset, name: string): DecoratedProperty {
  const descriptorObj = toDescriptorObject(name);
  const value = (asset as Record<string, any>)[name];
  return buildProperty(asset.config, descriptorObj, { value });
}

export function decorateAsset(asset: GqlAsset): DecoratedAsset {
  const { knownAsset, config } = getAssetConfig(asset.knownAssetUuid);

  const convertedFields = {
    installationDate: asset.installationDate ? DateTime.fromISO(asset.installationDate) : null
  };

  const propertyContainer = {};
  const thresholds = asset.thresholds?.values ?? {};
  const locked = !!asset.deviceLock?.expiration;

  const decoratedAsset: DecoratedAsset = {
    ...asset,
    ...convertedFields,
    properties: propertyContainer,
    thresholds,
    config,
    knownAsset,
    locked
  };

  createProperties(config, propertyContainer, asset.properties ?? {}, thresholds);
  createProperties(config, propertyContainer, asset.settings ?? {}, thresholds);
  createProperties(config, propertyContainer, asset.miscFields ?? {}, thresholds);

  syncPendingStateManagementRequests(decoratedAsset);

  return reactive(decoratedAsset);
}

export function updateThresholds(asset: DecoratedAsset, propertyName: string, thresholdArray: ThresholdPair[]): void {
  const propertyObj = getProperty(asset, propertyName);
  const thresholdFields = buildThresholdFields(propertyObj.config, thresholdArray);
  assign(propertyObj, thresholdFields);
}

export function propertyKey(property: DecoratedProperty): string {
  const { name, params, config } = property;
  const key = config.key ?? name;
  const indicesStr = params.map(p => `[${p}]`).join("");
  return `${key}${indicesStr}`;
}

export function getParentAssets(
  asset: GqlAsset | DecoratedAsset,
  predicate?: ListIterateeCustom<ParentLink, boolean> | undefined
): DecoratedAsset[] {
  const parentLinks = asset.parentLinks ?? [];
  const matchingLinks = filter(parentLinks, predicate);
  return matchingLinks.map(l => decorateAsset(l.parentAsset));
}

export function getChildAssets(
  asset: GqlAsset | DecoratedAsset,
  predicate?: ListIterateeCustom<ChildLink, boolean> | undefined
): DecoratedAsset[] {
  const childLinks = asset.childLinks ?? [];
  const matchingLinks = filter(childLinks, predicate);
  return matchingLinks.map(l => decorateAsset(l.childAsset));
}

export function isPropertySelectionByName(
  selection: PropertySelection | PropertySelectionByName
): selection is PropertySelectionByName {
  return typeof selection.property === "string";
}

export function resolvePropertySelection(selection: PropertySelection | PropertySelectionByName): PropertySelection {
  if (!isPropertySelectionByName(selection)) return selection;
  const { asset, property } = selection;
  return {
    asset,
    property: getProperty(asset, property)
  };
}

export function selectionKey(selection: PropertySelection | PropertySelectionByName): string {
  const descriptorObj = toDescriptorObject(selection.property);
  return `${selection.asset.assetUuid}-${descriptorObj.name}`;
}

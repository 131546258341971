import { ref, unref, computed } from "vue";
import { useQuery } from "@vue/apollo-composable";
import gql from "graphql-tag";
import { DateTime } from "luxon";
import { GqlTimeSeries, MaybeRef, TimeSeriesResult } from "@/types";
import { dateRangeToPeriod } from "@/utils/periods";

const MIN_PERIOD = 60;

export interface UseAverageCurrentTrendOptions {
  period?: MaybeRef<number | undefined>;
  cache?: MaybeRef<boolean>;
  enabled?: MaybeRef<boolean>;
}

export function useAverageCurrentTrend(
  assetUuids: MaybeRef<string[]>,
  startDate: MaybeRef<DateTime>,
  endDate: MaybeRef<DateTime>,
  { period, cache = false, enabled = true }: UseAverageCurrentTrendOptions = {}
): TimeSeriesResult {
  const seriesData = ref<GqlTimeSeries>();
  const loadingError = ref(false);

  const effectivePeriod = computed(() => {
    const passedPeriod = unref(period);
    const passedOrCalculatedPeriod = passedPeriod ?? dateRangeToPeriod(unref(startDate), unref(endDate));
    return Math.max(passedOrCalculatedPeriod, MIN_PERIOD);
  });

  const queryVariables = computed(() => {
    return {
      deviceUuids: unref(assetUuids),
      startDate: unref(startDate).setZone("utc"),
      endDate: unref(endDate).setZone("utc"),
      period: effectivePeriod.value
    };
  });

  const { onResult, onError, refetch, loading } = useQuery(
    gql`
      query AverageCurrentTrend($deviceUuids: [ID!]!, $startDate: DateTime!, $endDate: DateTime!, $period: Int!) {
        seriesData: averageCurrentTrend(
          deviceUuids: $deviceUuids
          startDate: $startDate
          endDate: $endDate
          period: $period
        )
      }
    `,
    queryVariables,
    () => ({
      enabled: unref(enabled),
      ...(unref(cache) ? {} : { fetchPolicy: "no-cache" }),
      notifyOnNetworkStatusChange: true
    })
  );

  onError(() => {
    loadingError.value = true;
  });

  onResult(queryResult => {
    if (queryResult.data) {
      seriesData.value = queryResult.data.seriesData;
    }
  });

  const series = computed(() => {
    return seriesData.value ?? undefined;
  });

  return { series, loadingError, refetch, loading };
}

import { ref, Ref } from "vue";
import { useQuery } from "@vue/apollo-composable";
import gql from "graphql-tag";
import { DeviceConfig, DeviceConfigType, MaybeRef } from "@/types";

export interface UseDeviceConfigsResult {
  deviceConfigs: Ref<DeviceConfig[] | undefined>;
  loadingError: Ref<boolean>;
  refetch: () => void;
  loading: Ref<boolean>;
}

export function useDeviceConfigs(
  buildingUuid: MaybeRef<string>,
  configType: MaybeRef<DeviceConfigType>
): UseDeviceConfigsResult {
  const deviceConfigs = ref<DeviceConfig[]>();
  const loadingError = ref(false);

  const { onResult, onError, refetch, loading } = useQuery(
    gql`
      query DeviceConfigs($buildingUuid: ID!, $configType: String!) {
        deviceConfigs: getBuildingDeviceConfig(buildingUuid: $buildingUuid, configType: $configType) {
          configUuid
          name
          settings
        }
      }
    `,
    {
      buildingUuid,
      configType
    },
    {
      fetchPolicy: "no-cache",
      notifyOnNetworkStatusChange: true
    }
  );

  onError(() => {
    loadingError.value = true;
  });

  onResult(queryResult => {
    if (queryResult.data) {
      deviceConfigs.value = queryResult.data.deviceConfigs;
    }
  });

  return { deviceConfigs, loadingError, refetch, loading };
}

import { computed, ComputedRef, unref } from "vue";
import { isNil } from "lodash";
import VueI18n from "vue-i18n";
import i18n from "@/plugins/i18n";
import { formatNumber } from "@/utils/number";
import { DecoratedAsset, DecoratedProperty, PropertyConfig, Unit, MaybeRef, StringOrTranslateKey } from "@/types";
import usePropertyWithUnit from "./use-property-with-unit";
import { propertyName, propertyOption } from "@/utils/models";

export interface UseBasicPropertyInfoOptions {
  unit?: MaybeRef<Unit | undefined>;
  nameOverride?: StringOrTranslateKey | null;
}

export interface UseBasicPropertyInfoResult {
  effectiveProperty: ComputedRef<DecoratedProperty>;
  propertyConfig: ComputedRef<PropertyConfig>;
  label: ComputedRef<string>;
  value: ComputedRef<string | null>;
  isValueNil: ComputedRef<boolean>;
  unit: ComputedRef<VueI18n.TranslateResult | undefined>;
}

export default function useBasicPropertyInfo(
  asset: MaybeRef<DecoratedAsset>,
  property: MaybeRef<DecoratedProperty>,
  options: UseBasicPropertyInfoOptions = {}
): UseBasicPropertyInfoResult {
  const { unit, nameOverride } = options;

  const { effectiveProperty, propertyConfig } = usePropertyWithUnit(asset, property, unit);

  const label = computed(() => {
    return propertyName(unref(asset), effectiveProperty.value, nameOverride);
  });

  const isValueNil = computed(() => {
    return isNil(effectiveProperty.value.value);
  });

  const value = computed(() => {
    const { dataType, defaultValue, options } = effectiveProperty.value.config;
    const hasOptions = options && options.length > 0;

    if (hasOptions || dataType === "boolean") {
      return propertyOption(effectiveProperty.value, defaultValue ?? false);
    } else if (dataType === "number") {
      return formatNumber(effectiveProperty.value.value, { format: propertyConfig.value.format });
    }

    return effectiveProperty.value?.value ?? null;
  });

  const unitStr = computed(() => {
    const unit = propertyConfig.value.unit;
    const showUnit = unit && !propertyConfig.value.hideValueUnit;
    return showUnit ? i18n.t(`units.${unit}`) : undefined;
  });

  return { effectiveProperty, propertyConfig, label, value, isValueNil, unit: unitStr };
}

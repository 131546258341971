import { ref, unref, Ref, computed } from "vue";
import { useQuery } from "@vue/apollo-composable";
import gql from "graphql-tag";
import { Asset, DecoratedAsset, MaybeRef } from "@/types";
import { decorateAsset } from "@/config/asset";
import { isBlank } from "@/utils/string";
import { deviceLock } from "@/gql/fragments/deviceLock";
import { useAssetSubscription } from "./use-asset-subscription";

export interface UseAssetOptions {
  includeBuilding?: MaybeRef<boolean>;
  includeChildLinks?: MaybeRef<boolean>;
  cache?: MaybeRef<boolean>;
  enabled?: MaybeRef<boolean>;
  subscribe?: boolean;
}

export interface UseAssetResult {
  asset: Ref<DecoratedAsset | undefined>;
  loadingError: Ref<boolean>;
  refetch: () => void;
  loading: Ref<boolean>;
}

export function useAsset(
  assetUuid: MaybeRef<string>,
  {
    includeBuilding = false,
    includeChildLinks = false,
    cache = false,
    enabled = true,
    subscribe = false
  }: UseAssetOptions = {}
): UseAssetResult {
  const rawAsset: Ref<Asset | undefined> = ref();
  const asset: Ref<DecoratedAsset | undefined> = ref();
  const loadingError = ref(false);

  if (isBlank(unref(assetUuid))) {
    // empty assetUuid, return empty result
    return {
      asset,
      loadingError,
      refetch: () => {
        /**/
      },
      loading: ref(false)
    };
  }

  const { onResult, onError, refetch, loading } = useQuery(
    gql`
      ${deviceLock}
      query AssetQuery($assetUuid: ID!, $includeBuilding: Boolean!, $includeChildLinks: Boolean!) {
        asset: asset(assetUuid: $assetUuid) {
          assetUuid
          accountUuid
          knownAssetUuid
          assetCategory {
            name
          }
          assetType {
            name
          }
          manufacturer {
            name
          }
          assetModel {
            name
          }
          smart
          assetCategoryUuid
          assetTypeUuid
          manufacturerUuid
          assetModelUuid
          name
          serialNumber
          installationDate
          floorX
          floorY
          building @include(if: $includeBuilding) {
            buildingUuid
            name
            timeZone
          }
          ... on Device {
            properties
            settings
            miscFields
            thresholds {
              values
            }
            ...DeviceLockFields
          }
          assignedDeviceConfigs {
            config {
              configUuid
              name
              configType
            }
            sync {
              synced
            }
          }
          childLinks @include(if: $includeChildLinks) {
            linkType
            childAsset {
              assetUuid
              knownAssetUuid
              name
            }
          }
        }
      }
    `,
    () => ({
      assetUuid: unref(assetUuid),
      includeBuilding: unref(includeBuilding),
      includeChildLinks: unref(includeChildLinks)
    }),
    () => ({
      enabled: unref(enabled),
      ...(unref(cache) ? {} : { fetchPolicy: "no-cache" }),
      notifyOnNetworkStatusChange: true
    })
  );

  onError(() => {
    loadingError.value = true;
  });

  onResult(queryResult => {
    if (queryResult.data) {
      rawAsset.value = queryResult.data.asset;
      asset.value = decorateAsset(queryResult.data.asset);
    }
  });

  if (subscribe) {
    const assets = computed(() => (asset.value ? [asset.value] : undefined));
    useAssetSubscription(assets);
  }

  return { asset, loadingError, refetch, loading };
}

import { ref, Ref } from "vue";
import { useQuery } from "@vue/apollo-composable";
import gql from "graphql-tag";
import { DeviceConfig, MaybeRef } from "@/types";

export interface UseDeviceConfigResult {
  deviceConfig: Ref<DeviceConfig | undefined>;
  loadingError: Ref<boolean>;
  refetch: () => void;
  loading: Ref<boolean>;
}

export function useDeviceConfig(configUuid: MaybeRef<string>): UseDeviceConfigResult {
  const deviceConfig = ref<DeviceConfig>();
  const loadingError = ref(false);

  const { onResult, onError, refetch, loading } = useQuery(
    gql`
      query DeviceConfig($configUuid: ID!) {
        deviceConfig: getDeviceConfig(configUuid: $configUuid) {
          configUuid
          name
          configType
          settings
        }
      }
    `,
    {
      configUuid
    },
    {
      fetchPolicy: "no-cache",
      notifyOnNetworkStatusChange: true
    }
  );

  onError(() => {
    loadingError.value = true;
  });

  onResult(queryResult => {
    if (queryResult.data) {
      deviceConfig.value = queryResult.data.deviceConfig;
    }
  });

  return { deviceConfig, loadingError, refetch, loading };
}

import { ref, unref, computed } from "vue";
import { useQuery } from "@vue/apollo-composable";
import gql from "graphql-tag";
import { DateTime } from "luxon";
import { MaybeRef, DecoratedProperty, GqlTimeSeries, TimeSeriesResult } from "@/types";

export interface UsePropertyHistoryOptions {
  cache?: MaybeRef<boolean>;
  enabled?: MaybeRef<boolean>;
}

export function usePropertyHistory(
  assetUuid: MaybeRef<string>,
  property: MaybeRef<DecoratedProperty>,
  startDate: MaybeRef<DateTime>,
  endDate: MaybeRef<DateTime>,
  { cache = false, enabled = true }: UsePropertyHistoryOptions = {}
): TimeSeriesResult {
  const seriesData = ref<GqlTimeSeries>();
  const loadingError = ref(false);

  const queryVariables = computed(() => {
    return {
      deviceUuid: unref(assetUuid),
      property: unref(property).config.key ?? unref(property).name,
      startDate: unref(startDate).setZone("utc"),
      endDate: unref(endDate).setZone("utc")
    };
  });

  const { onResult, onError, refetch, loading } = useQuery(
    gql`
      query PropertyHistory($deviceUuid: ID!, $property: String!, $startDate: DateTime!, $endDate: DateTime!) {
        series: propertyHistory(
          deviceUuid: $deviceUuid
          filter: { property: $property, startDate: $startDate, endDate: $endDate }
        )
      }
    `,
    queryVariables,
    () => ({
      enabled: unref(enabled),
      ...(unref(cache) ? {} : { fetchPolicy: "no-cache" }),
      notifyOnNetworkStatusChange: true
    })
  );

  onError(() => {
    loadingError.value = true;
  });

  onResult(queryResult => {
    if (queryResult.data) {
      seriesData.value = queryResult.data.series;
    }
  });

  const series = computed(() => {
    return seriesData.value ?? undefined;
  });

  return { series, loadingError, refetch, loading };
}

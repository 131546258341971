import gql from "graphql-tag";
import { apolloClient } from "@/plugins/vue-apollo";

export default function (uuid: string, priority: number): Promise<any> {
  return apolloClient.mutate({
    mutation: gql`
      mutation ($uuid: ID!, $priority: Int!) {
        prioritizeDocument(uuid: $uuid, priority: $priority) {
          statusCode
          message
        }
      }
    `,
    variables: {
      uuid: uuid,
      priority: priority
    }
  });
}

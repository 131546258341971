import { forOwn, fromPairs, isNil, round } from "lodash";
import { KNOWN_ASSETS } from "@/config/known-asset";
import { createConversionFns, unitCategory } from "./number";
import {
  Unit,
  AssetConfig,
  FieldDescriptor,
  UnitOrDefault,
  PropertyConfig,
  FieldDescriptorObject,
  GqlProperty,
  DecoratedProperty,
  ThresholdPair,
  ThresholdFields,
  PropertyContainer,
  PropertySelection,
  PropertySelectionByName,
  UnitCategory
} from "@/types";
import { setIndexedField, toDescriptorObject } from "./indexed-field";
import { DEFAULT_UNIT } from "@/config/constants";
import { convertPairsToThresholds } from "./models";
import { resolvePropertySelection, selectionKey } from "@/config/asset";

export function comparablePropertiesByKnownAsset(unit?: Unit | null): Record<string, string[]> {
  const pairs: [string, string[]][] = KNOWN_ASSETS.map(knownAsset => {
    const assetConfig = knownAsset.config.default;
    const properties = comparablePropertiesForAsset(assetConfig, unit);
    return [knownAsset.knownAssetUuid, properties];
  });
  return fromPairs(pairs);
}

function comparablePropertiesForAsset(assetConfig: AssetConfig, unit?: Unit | null): string[] {
  const propertyNames = Object.keys(assetConfig.properties);
  const thisUnitCategory = unit ? unitCategory(unit) : null;

  return propertyNames.reduce((acc, name) => {
    const { comparable, unit: otherUnit } = assetConfig.properties[name];
    const otherUnitCategory = otherUnit ? unitCategory(otherUnit) : null;
    if (comparable && (isNil(thisUnitCategory) || (thisUnitCategory && otherUnitCategory === thisUnitCategory))) {
      acc.push(name);
    }
    return acc;
  }, [] as string[]);
}

export function getPropertyConfig(
  assetConfig: AssetConfig,
  descriptor: FieldDescriptor,
  unit: UnitOrDefault | null = null
): PropertyConfig {
  const { name } = toDescriptorObject(descriptor);
  let config: PropertyConfig | undefined = assetConfig.properties?.[name];
  if (!config) throw Error(`Property config not found for: ${name}`);

  const srcUnit = config.unit;
  const destUnit = unit === DEFAULT_UNIT ? config.unitSelectorFn?.() : unit;

  if (srcUnit && destUnit && destUnit !== srcUnit) {
    const altConfig = config.altUnits?.[destUnit] ?? {};
    const round = altConfig.graphFormat ? altConfig.graphFormat === "integer" : altConfig?.format === "integer";
    config = {
      ...config,
      ...altConfig,
      unit: destUnit,
      ...createConversionFns(srcUnit, destUnit, round)
    };
  }

  return config;
}

export function buildThresholdFields(propertyConfig: PropertyConfig, thresholdArray: ThresholdPair[]): ThresholdFields {
  const convert = propertyConfig.convertValueFn;
  if (convert) {
    thresholdArray = thresholdArray.map(threshold => {
      return { ...threshold, compareValue: round(convert(threshold.compareValue) ?? 0) };
    });
  }

  return {
    thresholdArray,
    thresholds: convertPairsToThresholds(thresholdArray)
  };
}

export function buildProperty(
  assetConfig: AssetConfig,
  descriptor: FieldDescriptorObject,
  attributes: Partial<GqlProperty> | null,
  thresholdArray: ThresholdPair[] = []
): DecoratedProperty {
  const propertyConfig = getPropertyConfig(assetConfig, descriptor.name);

  return {
    ...descriptor,
    value: attributes?.value ?? null,
    timestamp: attributes?.stamp ?? null,
    alias_name: attributes?.alias_name ?? null,
    update_stamp: attributes?.update_stamp ?? null,
    state: attributes?.state ?? null,
    pending: attributes?.pending ?? false,
    state_info: attributes?.state_info ?? {},
    ...buildThresholdFields(propertyConfig, thresholdArray),
    config: propertyConfig
  };
}

export function createProperty(
  assetConfig: AssetConfig,
  propertyContainer: PropertyContainer,
  descriptor: FieldDescriptorObject,
  attributes: Partial<GqlProperty> | null,
  thresholdArray: ThresholdPair[] = []
): DecoratedProperty {
  const property = buildProperty(assetConfig, descriptor, attributes, thresholdArray);
  setIndexedField(propertyContainer, descriptor, property);
  return property;
}

export function createProperties(
  assetConfig: AssetConfig,
  propertyContainer: PropertyContainer,
  gqlProperties: Record<string, GqlProperty<any> | null>,
  thresholdMap: Record<string, ThresholdPair[]> = {}
): void {
  forOwn(gqlProperties, (gqlProperty, key) => {
    const descriptor = parseKey(assetConfig, key);
    if (!descriptor) return;

    const thresholdArray = thresholdMap[descriptor.name] ?? [];
    createProperty(assetConfig, propertyContainer, descriptor, gqlProperty, thresholdArray);
  });
}

export function parseKey(assetConfig: AssetConfig, key: string): FieldDescriptorObject | undefined {
  const keyParts = key.match(/^(.*?)(\[.*)?$/);
  if (!keyParts) return undefined;

  const namePart = keyParts[1];
  const paramsPart: string | undefined = keyParts[2];

  const name = assetConfig.pathMap[namePart];
  if (!name) return undefined;
  let params: number[];

  if (paramsPart) {
    const numbers = paramsPart.match(/\d+/g);
    params = numbers ? numbers.map(m => parseInt(m)) : [];
  } else {
    params = [];
  }

  const fieldConfig = assetConfig.properties[name];
  if (!fieldConfig || fieldConfig.dimensions !== params.length) {
    return undefined;
  }

  return {
    name,
    params
  };
}

/**
 * Groups property selections by comparable units
 * @param selections The property selections to group
 * @returns An array of selection groups, where each group contains selections with comparable units
 */
export function groupSelectionsByUnitCategory(selectionList: PropertySelectionByName[]): PropertySelection[][] {
  const resolvedSelections = selectionList.map(selection => resolvePropertySelection(selection));

  // Group by unit category
  const groupsByCategory = new Map<UnitCategory | "no-unit" | null, PropertySelection[]>();

  for (const selection of resolvedSelections) {
    const unit = selection.property.config.unit;
    const category = unit ? unitCategory(unit) : null;
    const key = category || "no-unit";

    if (!groupsByCategory.has(key)) {
      groupsByCategory.set(key, []);
    }

    // Check if this selection is already in the group (avoid duplicates)
    const group = groupsByCategory.get(key)!;
    const selKey = selectionKey(selection);
    if (!group.some(item => selectionKey(item) === selKey)) {
      group.push(selection);
    }
  }

  // Convert map to array of groups
  return Array.from(groupsByCategory.values());
}

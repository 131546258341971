import gql from "graphql-tag";
import { apolloClient } from "@/plugins/vue-apollo";
import store from "@/store";

export default function (manufacturerUuid: string, name: string): Promise<any> {
  return apolloClient
    .mutate({
      mutation: gql`
        mutation ($accountUuid: ID!, $manufacturerUuid: ID!, $name: String!) {
          createAssetModel(accountUuid: $accountUuid, manufacturerUuid: $manufacturerUuid, name: $name) {
            assetModelUuid
          }
        }
      `,
      variables: {
        accountUuid: store.getters.contextAccountUuid,
        manufacturerUuid,
        name
      }
    })
    .then(response => {
      return response.data.createAssetModel;
    });
}

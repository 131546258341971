import { merge, fromPairs, toPairs, mapValues } from "lodash";
import { PartialAssetConfig, AssetConfig, PropertyConfig } from "@/types";
import { readFieldConfig } from "@/config/form";
import { DEFAULT_GRAPH_SERIES_CONFIG, DEFAULT_STALE_DATA_DURATION } from "./constants";

const DEFAULT_PROPERTY: PropertyConfig = {
  dataType: "number",
  category: "properties",
  dimensions: 0,
  dependsOnFields: [],
  labelKey: "label",
  supportsAlias: false,
  i18nNamespace: "asset",
  format: "integer",
  fieldConfig: {},
  ...DEFAULT_GRAPH_SERIES_CONFIG
};

const DEFAULT_ASSET: AssetConfig = {
  i18nNamespace: "asset",
  components: {},
  staleDataDuration: DEFAULT_STALE_DATA_DURATION,
  categoryProperties: {},
  properties: {},
  fields: {},
  pathMap: {}
};

function readPropertyConfig(config: Partial<PropertyConfig>): PropertyConfig {
  return {
    ...DEFAULT_PROPERTY,
    ...config
  };
}

export function readAssetConfig(config: PartialAssetConfig): AssetConfig {
  const properties = mapValues(config.properties, propertyConfig => {
    return readPropertyConfig({ ...propertyConfig, i18nNamespace: config.i18nNamespace });
  });
  const fields = mapValues(config.fields, readFieldConfig);
  const pathMap = {
    ...mapKeysToNames(properties),
    ...mapKeysToNames(fields)
  };

  return {
    ...DEFAULT_ASSET,
    ...config,
    properties,
    fields,
    pathMap
  };
}

export function mergeAssetConfig(base: PartialAssetConfig, config: PartialAssetConfig): AssetConfig {
  return merge({}, base, {
    ...DEFAULT_ASSET,
    ...readAssetConfig(merge({}, base, config))
  });
}

function mapKeysToNames(fields: Record<string, { key?: string }>): Record<string, string> {
  const pairs = toPairs(fields).map(([name, fieldConfig]) => {
    const key = fieldConfig.key ?? name;
    return [key, name];
  });
  return fromPairs(pairs);
}

import gql from "graphql-tag";

export const deviceLock = gql`
  fragment DeviceLockFields on Device {
    deviceLock {
      expiration
      progress {
        operation
        total
        done
        pending
        properties
      }
      lockOwnerUuid
      expiration
      lockOwner {
        contactInfo {
          name
        }
      }
    }
  }
`;

import { Ref } from "vue";
import { Document } from "@/types";
import prioritizeDocumentMutation from "@/gql/prioritize-document-mutation";

export interface UseDocumentResult {
  prioritizeDocument: (document: Document, priority: number) => Promise<any>;
  pinDocument: (document: Document, documentList: Ref<Document[]>) => Promise<any>;
}

export function useDocument(): UseDocumentResult {
  function prioritizeDocument(document: Document, priority: number): Promise<any> {
    return prioritizeDocumentMutation(document.uuid, priority);
  }

  // eslint-disable-next-line
  function pinDocument(document: Document, _documentList: Ref<Document[]>): Promise<any> {
    if (document.priority === 1) {
      return prioritizeDocument(document, 0);
    }
    // unpin any current pins
    //documentList.value.filter(doc => doc.priority > 0).forEach(doc => prioritizeDocument(doc, 0));

    // pin the given document
    return prioritizeDocument(document, 1);
  }

  return {
    prioritizeDocument,
    pinDocument
  };
}

import { DecoratedAsset, DecoratedProperty, MaybeRef, PropertyOrName } from "@/types";
import useBasicPropertyInfo, {
  UseBasicPropertyInfoOptions,
  UseBasicPropertyInfoResult
} from "./use-basic-property-info";
import usePropertyTimestamps, { UsePropertyTimestampsResult } from "./use-property-timestamps";
import usePropertyIndicator, {
  UsePropertyIndicatorOptions,
  UsePropertyIndicatorResult
} from "./use-property-indicator";
import { unref } from "vue";
import { getProperty } from "@/config/asset";

export interface UseDecoratedPropertyOptions extends UseBasicPropertyInfoOptions, UsePropertyIndicatorOptions {}

export interface UseDecoratedPropertyResult
  extends UseBasicPropertyInfoResult,
    UsePropertyTimestampsResult,
    UsePropertyIndicatorResult {}

export default function useDecoratedProperty(
  asset: MaybeRef<DecoratedAsset>,
  property: MaybeRef<PropertyOrName>,
  options: UseDecoratedPropertyOptions = {}
): UseDecoratedPropertyResult {
  const { unit, nameOverride, disableThresholds = false, disableStalenessIndicator = false } = options;

  const propertyValue = unref(property);
  let actualProperty: DecoratedProperty;
  if (typeof propertyValue === "string") {
    actualProperty = getProperty(unref(asset), propertyValue);
  } else {
    actualProperty = propertyValue;
  }

  const { effectiveProperty, ...basicPropertyInfo } = useBasicPropertyInfo(asset, actualProperty, {
    unit,
    nameOverride
  });

  const { relativeTimestamp, ...propertyTimestamps } = usePropertyTimestamps(effectiveProperty);

  const propertyIndicator = usePropertyIndicator(asset, effectiveProperty, relativeTimestamp, {
    disableThresholds,
    disableStalenessIndicator
  });

  return {
    effectiveProperty,
    ...basicPropertyInfo,
    relativeTimestamp,
    ...propertyTimestamps,
    ...propertyIndicator
  };
}

import Vue from "vue";
import { fromPairs, assign, omit, isNil } from "lodash";
import { GqlProperty, DecoratedAsset, StateManagementRequestStart, DecoratedProperty, AssetDataChange } from "@/types";
import { getOptionalProperty } from "@/config/asset";
import { buildProperty, parseKey, createProperty } from "@/utils/properties";
import { parseTimestamp } from "@/utils/date";

export function startStateManagementRequest(asset: DecoratedAsset, properties: string[]): void {
  const propPairs: [string, boolean][] = properties.map(p => [p, false]);
  const propObject = fromPairs(propPairs);

  Vue.set(asset, "updateStatus", {
    properties: propObject
  });
}

export function startStateManagementRequests(assets: DecoratedAsset[], requests: StateManagementRequestStart[]): void {
  requests.forEach(request => {
    const asset = assets.find(a => a.assetUuid === request.assetUuid);
    if (asset) {
      asset.locked = true;
      startStateManagementRequest(asset, request.properties);
    }
  });
}

export function syncPendingStateManagementRequests(asset: DecoratedAsset): void {
  if (asset.deviceLock) {
    const progress = asset.deviceLock.progress;
    if (progress) {
      startStateManagementRequest(asset, progress.properties);
      // update statemanagemnt properties with progress
      progress.properties.forEach(propertyKey => {
        const descriptor = parseKey(asset.config, propertyKey);
        if (!descriptor) return;

        const property = getOptionalProperty(asset, descriptor);
        if (property && !property.pending) {
          markPropertyComplete(asset, propertyKey);
        }
      });
    }
  }
}

export function clearAssetUpdate(asset: DecoratedAsset): void {
  Vue.delete(asset, "updateStatus");
}

export function markPropertyComplete(asset: DecoratedAsset, propertyKey: string): void {
  if (!asset.updateStatus) return;
  asset.updateStatus.properties[propertyKey] = true;
}

export function assetUpdateMatchesProperties(asset: DecoratedAsset, properties: string[] | null): boolean {
  const { updateStatus } = asset;

  if (!updateStatus) return false;
  if (!properties) return false;

  return properties.some(p => p in updateStatus.properties && !updateStatus.properties[p]);
}

export function updateAssetProperties(assets: DecoratedAsset[], changes: AssetDataChange[]): void {
  changes.forEach(change => {
    const asset = assets.find(a => a.assetUuid === change.assetUuid);
    if (asset) {
      updateDecoratedAssetProperty(asset, change);
    }
  });
  // Just in case we missed any updates
  assets.filter(a => !a.locked).forEach(clearAssetUpdate);
}

export function updateDecoratedAssetProperty(asset: DecoratedAsset, change: AssetDataChange): void {
  const descriptor = parseKey(asset.config, change.property);
  if (!descriptor) return;

  const attributes: Partial<GqlProperty> = {
    value: change.value,
    stamp: change.stamp,
    update_stamp: change.updateStamp,
    state: change.state,
    pending: change.pending,
    state_info: change.stateInfo
  };

  let updateApplied = false;
  const currentProperty = getOptionalProperty(asset, descriptor);
  const thresholdArray = asset.thresholds[descriptor.name] ?? [];

  if (currentProperty) {
    const newProperty = buildProperty(asset.config, descriptor, attributes, thresholdArray);
    if (shouldUpdateProperty(newProperty, currentProperty)) {
      // Copy into existing property to preserve reactivity
      checkPropertyCompletion(asset, currentProperty, newProperty, change.property);
      assign(currentProperty, omit(newProperty, "alias_name"));
      updateApplied = true;
    }
  } else {
    createProperty(asset.config, asset.properties, descriptor, attributes, thresholdArray);
    updateApplied = true;
  }

  if (change && updateApplied && !isNil(change.deviceLock)) {
    asset.locked = change.deviceLock;
  }
}

function checkPropertyCompletion(
  asset: DecoratedAsset,
  currentProperty: DecoratedProperty,
  newProperty: DecoratedProperty,
  propertyKey: string
): void {
  if (!asset.updateStatus) return;

  if (currentProperty.pending && !newProperty.pending) {
    markPropertyComplete(asset, propertyKey);
  }
}

function shouldUpdateProperty(newProperty: DecoratedProperty, currentProperty: DecoratedProperty): boolean {
  const newTimestamp = parseTimestamp(newProperty.timestamp);
  if (newTimestamp === null) return false;

  const currentTimestamp = parseTimestamp(currentProperty.timestamp);
  if (currentTimestamp === null) return true;

  return newTimestamp > currentTimestamp;
}

import { useLazyQuery } from "@vue/apollo-composable";
import gql from "graphql-tag";
import { EventPage } from "@/types";
import { useLazyQueryGenericResult } from "./use-lazy-query-generic-result";
import { commentFields } from "./fragments/comment";

export default function useBuildingEventsLazyQuery(
  objectUuid: string,
  eventType: string | undefined,
  noteFilter: boolean,
  mutationNames: string[] | undefined,
  excludeMutationNames: string[] | undefined,
  limit: number,
  offset: number
): useLazyQueryGenericResult<
  {
    objectUuid: string;
    eventType: string | undefined;
    limit: number;
    offset: number;
    noteFilter: boolean;
    mutationNames: string[] | undefined;
    excludeMutationNames: string[] | undefined;
  },
  { eventPage: EventPage }
> {
  return useLazyQuery(
    gql`
      ${commentFields}
      query BuildingEventsQuery(
        $objectUuid: ID!
        $eventType: String
        $noteFilter: Boolean
        $mutationNames: [String!]
        $excludeMutationNames: [String!]
        $limit: Int
        $offset: Int
      ) {
        eventPage: buildingEvents(
          buildingUuid: $objectUuid
          filter: {
            eventType: $eventType
            noteFilter: $noteFilter
            mutationNames: $mutationNames
            excludeMutationNames: $excludeMutationNames
            offset: $offset
            limit: $limit
          }
        ) {
          data {
            eventUuid
            accountUuid
            buildingUuid
            floorUuid
            assetUuid
            ownerUuid
            stamp
            eventType
            eventData
            asset {
              assetUuid
              knownAssetUuid
              name
              ... on Device {
                properties
                settings
              }
            }
            building {
              name
              timeZone
            }
            floor {
              name
              floorplan {
                url
              }
              position
            }
            user {
              emailAddress
              contactInfo {
                emailAddress
                name
              }
            }
            comments {
              data {
                ...CommentFields
              }
            }
          }
          pageInfo {
            totalCount
            pageCount
            limit
            offset
          }
        }
      }
    `,
    {
      objectUuid: objectUuid,
      eventType: eventType,
      noteFilter: noteFilter,
      mutationNames: mutationNames,
      excludeMutationNames: excludeMutationNames,
      limit: limit,
      offset: offset
    },
    {
      fetchPolicy: "no-cache"
    }
  );
}

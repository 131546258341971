import { isNil, partition, mean } from "lodash";
import VueI18n from "vue-i18n";
import i18n from "@/plugins/i18n";
import {
  Format,
  ThresholdPair,
  Unit,
  GqlTimeSeries,
  GqlTimeSeriesDatum,
  ScalarTimeSeries,
  UnitConversionFns,
  UnitConversionFn,
  BaseUnitConversionFn,
  ParamProvider,
  DecoratedProperty,
  UnitCategory
} from "@/types";
import { DEFAULT_POWER_FACTOR, DEFAULT_VOLTAGE } from "@/config/constants";
import { adjustTimestampToTimeZone } from "./date";

const BUFFER_RANGE_PCT = 0.15;
const CATEGORY_FORMATS = new Set<Format>(["boolean_yes_no"]);

type OptionalNumber = number | null | undefined;

interface FormatNumberOptions {
  format?: Format;
  fallback?: string;
}

export function floatToPct(value: OptionalNumber): number | null {
  return isNil(value) ? null : Math.round(value * 100);
}

export function isCategoryFormat(format?: Format | null): boolean {
  if (isNil(format)) return false;
  return CATEGORY_FORMATS.has(format);
}

export function formatNumber(
  value: OptionalNumber,
  options: FormatNumberOptions = { format: "integer" }
): string | null {
  const { format, fallback } = options;

  if (format && isCategoryFormat(format)) {
    return formatNumberAsCategory(value, options);
  }

  if (isNil(value)) return fallback ?? null;
  if (format === undefined) return value.toString();

  return i18n.n(value, format);
}

export function formatNumberAsCategory(
  value: OptionalNumber,
  options: FormatNumberOptions = { format: "boolean_yes_no" }
): string | null {
  const { format, fallback } = options;

  if (isNil(value)) return fallback ?? null;
  if (format === undefined) return value.toString();

  return i18n.t(`categories.boolean_yes_no.${value}`).toString();
}

function getParam(params: ParamProvider | undefined, key: string, defaultValue?: number): number {
  const value = params ? params(key) : undefined;
  if (isNil(value)) {
    if (isNil(defaultValue)) throw Error(`Param not provided: ${key}`);
    return defaultValue;
  }
  return value;
}

const UNIT_CATEGORIES: Record<Unit, UnitCategory> = {
  degrees_c: "temperature",
  degrees_f: "temperature",
  degrees_c_relative: "temperature_relative",
  degrees_f_relative: "temperature_relative",
  pct: "percentage",
  ppm: "concentration",
  ppb: "concentration",
  mcg_m3: "concentration_by_volume",
  tvoc_level: "tvoc_level",
  lux: "illuminance",
  kwh: "energy",
  cfm: "volume_flow",
  mins: "time",
  milliamps: "electric_current",
  amps: "electric_current",
  kw: "power",
  volts: "voltage",
  people: "count",
  db: "sound_pressure_level"
};

const CONVERSIONS: Partial<Record<Unit, Partial<Record<Unit, BaseUnitConversionFn>>>> = {
  degrees_c: {
    degrees_f: n => (n * 9) / 5 + 32
  },
  degrees_f: {
    degrees_c: n => ((n - 32) * 5) / 9
  },
  degrees_c_relative: {
    degrees_f_relative: n => (n * 9) / 5
  },
  degrees_f_relative: {
    degrees_c_relative: n => (n * 5) / 9
  },
  ppm: {
    ppb: n => n * 1000
  },
  ppb: {
    ppm: n => n / 1000
  },
  milliamps: {
    amps: n => n / 1000.0,
    kw: (n, params) =>
      (n * getParam(params, "voltage", DEFAULT_VOLTAGE) * getParam(params, "power_factor", DEFAULT_POWER_FACTOR)) /
      1000000
  },
  amps: {
    milliamps: n => n * 1000.0,
    amps: n => n,
    kw: (n, params) =>
      (n * getParam(params, "voltage", DEFAULT_VOLTAGE) * getParam(params, "power_factor", DEFAULT_POWER_FACTOR)) / 1000
  },
  kw: {
    milliamps: (n, params) =>
      ((n * 1000000) / getParam(params, "voltage", DEFAULT_VOLTAGE)) *
      getParam(params, "power_factor", DEFAULT_POWER_FACTOR),
    amps: (n, params) =>
      ((n * 1000) / getParam(params, "voltage", DEFAULT_VOLTAGE)) *
      getParam(params, "power_factor", DEFAULT_POWER_FACTOR)
  }
};

function getConversionFn(
  srcUnit: Unit | undefined,
  destUnit: Unit | undefined,
  roundResult = false
): UnitConversionFn | undefined {
  if (!destUnit || !srcUnit || destUnit === srcUnit) return undefined;

  const baseFn = CONVERSIONS[srcUnit]?.[destUnit];
  if (baseFn === undefined) throw Error(`Unit conversion not supported for ${srcUnit} -> ${destUnit}`);

  const convertFn: UnitConversionFn = (n, params) => (isNil(n) ? n : baseFn(n, params));

  let finalFn = convertFn;
  if (roundResult) {
    finalFn = (n, params) => round(convertFn(n, params));
  }

  return finalFn;
}

export function convertUnitWithFn(
  value: number | null,
  convertFn: UnitConversionFn | undefined,
  params?: ParamProvider
): number | null {
  if (isNil(convertFn)) return value;
  return convertFn(value, params);
}

export function convertUnit(
  value: number | null,
  srcUnit: Unit,
  destUnit: Unit,
  params?: ParamProvider
): number | null {
  const convertFn = getConversionFn(srcUnit, destUnit);
  return convertUnitWithFn(value, convertFn, params);
}

export function createConversionFns(
  srcUnit: Unit | undefined,
  destUnit: Unit | undefined,
  roundDest = false
): UnitConversionFns {
  const srcConvertFn = getConversionFn(srcUnit, destUnit, roundDest);
  const destConvertFn = getConversionFn(destUnit, srcUnit);

  return {
    convertValueFn: srcConvertFn ?? undefined,
    deconvertValueFn: destConvertFn ?? undefined
  };
}

export function unitCategory(unit: Unit): UnitCategory {
  return UNIT_CATEGORIES[unit];
}

export function unitsAreComparable(unitA: Unit, unitB: Unit): boolean {
  if (unitA === unitB) return true;
  if (unitCategory(unitA) === unitCategory(unitB)) return true;
  return false;
}

export function round(value: number | null): number | null {
  return isNil(value) ? value : Math.round(value);
}

export function formatConfig(format: Format): VueI18n.NumberFormatOptions {
  return i18n.getNumberFormat(i18n.locale)[format];
}

function separateThresholds(thresholds: ThresholdPair[]): [number[], number[]] {
  const [lower, upper] = partition(thresholds, t => t.violationLevel < 0);
  return [lower.map(t => t.compareValue), upper.map(t => t.compareValue)];
}

function fitBoundSide(bound: number, thresholds: number[], currentValue: number | null, upperSide: boolean): number {
  if (thresholds.length === 0) return bound;

  const values = [...thresholds];
  if (!isNil(currentValue)) values.push(currentValue);

  // Set new bound based on thresholds and the current value. This probably
  // collapses the bound to a smaller range, but it's possible that it expands
  // it instead.
  const compFn = upperSide ? Math.max : Math.min;
  return compFn(...values);
}

function clampRange(lower: number, upper: number, min: number, max: number): [number, number] {
  return [Math.max(lower, min), Math.min(upper, max)];
}

export function fitBoundsToThresholds(
  min: number,
  max: number,
  thresholds: ThresholdPair[],
  currentValue: number | null = null
): [number, number] {
  const [lowerThresholds, upperThresholds] = separateThresholds(thresholds);

  let newMin = fitBoundSide(min, lowerThresholds, currentValue, false);
  let newMax = fitBoundSide(max, upperThresholds, currentValue, true);
  [newMin, newMax] = clampRange(newMin, newMax, min, max);

  // Add a buffer based on new range, then clamp again, since buffer
  // shouldn't extend outside of original bounds.
  const buffer = (newMax - newMin) * BUFFER_RANGE_PCT;
  [newMin, newMax] = clampRange(newMin - buffer, newMax + buffer, min, max);

  return [newMin, newMax];
}

export function transformSeriesData(
  data: GqlTimeSeries,
  property: DecoratedProperty,
  timeZone: string,
  convertValueFn: UnitConversionFn | undefined,
  params?: ParamProvider
): Record<string, ScalarTimeSeries> {
  const series: ScalarTimeSeries = data.map(([time, value]) => {
    const millis = adjustTimestampToTimeZone(new Date(time).getTime(), timeZone);
    let convertedValue = convertDatumToNumber(property.name, value);
    if (convertValueFn) convertedValue = convertValueFn(convertedValue, params);
    return [millis, convertedValue];
  });
  return { main: series };
}

export function transformTextualSeriesData(
  data: GqlTimeSeries,
  property: DecoratedProperty,
  timeZone: string
): Record<string, ScalarTimeSeries> {
  const pointConfig = property.config.graphPointConfig;
  if (!pointConfig) return {};

  const results: Record<string, ScalarTimeSeries> = {};
  for (const key of Object.keys(pointConfig)) {
    results[key] = [];
  }

  let lastValue = null;

  for (const [time, stringValue] of data) {
    const millis = adjustTimestampToTimeZone(new Date(time).getTime(), timeZone);
    const value = typeof stringValue === "string" ? stringValue : null;
    if (!value) continue;

    const config = pointConfig[value] ?? null;
    if (!config) continue;

    results[value].push([millis, config.y]);
    if (lastValue && lastValue !== value) {
      const lastConfig = pointConfig[lastValue] ?? null;
      if (lastConfig) {
        results[lastValue].push([millis - 1, lastConfig.y, { fake: true }]);
      }
      results[lastValue].push([millis, null, { fake: true }]);
    }

    lastValue = value;
  }

  return results;
}

function convertDatumToNumber(propertyName: string, datum: GqlTimeSeriesDatum): number | null {
  if (datum === null || typeof datum === "number") return datum;
  if (propertyName === "light_level") return getLux(datum);
  return parseFloat(datum);
}

function getLux(s: string): number | null {
  const arr = toNumbersArray(s);
  return isNaN(mean(arr)) ? null : Math.floor(mean(arr));
}

function toNumbersArray(s: string): number[] {
  const regex = /(\d+)/g;
  const result = s.match(regex);
  if (!result) return [];
  return result.map(Number);
}

import { ref, unref, computed } from "vue";
import { useQuery } from "@vue/apollo-composable";
import gql from "graphql-tag";
import { DateTime } from "luxon";
import { MaybeRef, DecoratedProperty, GqlTimeSeries, TimeSeriesResult, Aggregation } from "@/types";
import { dateRangeToPeriod } from "@/utils/periods";

export interface UsePropertyTrendOptions {
  period?: MaybeRef<number | undefined>;
  aggregation?: MaybeRef<Aggregation>;
  cache?: MaybeRef<boolean>;
  enabled?: MaybeRef<boolean>;
}

export function usePropertyTrend(
  assetUuid: MaybeRef<string>,
  property: MaybeRef<DecoratedProperty>,
  startDate: MaybeRef<DateTime>,
  endDate: MaybeRef<DateTime>,
  { period, aggregation = "AVERAGE", cache = false, enabled = true }: UsePropertyTrendOptions = {}
): TimeSeriesResult {
  const seriesData = ref<GqlTimeSeries>();
  const loadingError = ref(false);

  const effectivePeriod = computed(() => {
    if (unref(aggregation) === "RAW") return 0;

    const passedPeriod = unref(period);
    if (passedPeriod) return passedPeriod;

    return dateRangeToPeriod(unref(startDate), unref(endDate));
  });

  const queryVariables = computed(() => {
    return {
      deviceUuid: unref(assetUuid),
      property: unref(property).config.key ?? unref(property).name,
      aggregation: unref(aggregation) === "RAW" ? "AVERAGE" : unref(aggregation),
      startDate: unref(startDate).setZone("utc"),
      endDate: unref(endDate).setZone("utc"),
      period: effectivePeriod.value
    };
  });

  const { onResult, onError, refetch, loading } = useQuery(
    gql`
      query PropertyTrend(
        $deviceUuid: ID!
        $property: String!
        $startDate: DateTime!
        $endDate: DateTime!
        $period: Int!
        $aggregation: AggregationType!
      ) {
        series: propertyTrend(
          deviceUuid: $deviceUuid
          filter: {
            property: $property
            startDate: $startDate
            endDate: $endDate
            period: $period
            aggregation: $aggregation
          }
        )
      }
    `,
    queryVariables,
    () => ({
      enabled: unref(enabled),
      ...(unref(cache) ? {} : { fetchPolicy: "no-cache" }),
      notifyOnNetworkStatusChange: true
    })
  );

  onError(() => {
    loadingError.value = true;
  });

  onResult(queryResult => {
    if (queryResult.data) {
      seriesData.value = queryResult.data.series;
    }
  });

  const series = computed(() => {
    return seriesData.value ?? undefined;
  });

  return { series, loadingError, refetch, loading };
}

import { mergeAssetConfig } from "@/config/asset-config-reader";
import baseDeviceConfig from "@/config/base-device";

const config = mergeAssetConfig(baseDeviceConfig, {
  i18nNamespace: "gateway",
  components: {
    AssetDashboardTab: () => import("@/gateway/components/DeviceDashboardTab.vue"),
    AssetInspectorStatus: {
      component: () => import("@/gateway/components/AssetInspectorStatus.vue")
    }
  },
  categoryProperties: {
    rssi: { property: "lte_rssi" }
  },
  properties: {
    firmware: {
      dataType: "string"
    },
    gateway_timestamp: {
      dataType: "string"
    },
    connection_type: {
      dataType: "string"
    },
    current_network: {
      dataType: "string",
      graphPointConfig: {
        Bell: { y: 100, color: "#22488C" },
        Rogers: { y: 100, color: "#1D1D1D" },
        "T-Mobile": { y: 100, color: "#DD00A9" },
        "AT&T": { y: 100, color: "#4290D2" },
        TELUS: { y: 100, color: "#462A69" }
      }
    },
    lte_rssi: {
      dataType: "number",
      unit: "db"
    },
    lte_rsrq: {
      dataType: "number"
    },
    lte_rsrp: {
      dataType: "number"
    }
  }
});

export default config;

import gql from "graphql-tag";
import { apolloClient } from "@/plugins/vue-apollo";

export default function (configUuid: string, assetUuids: string[] | null = null, force = false): Promise<any> {
  return apolloClient
    .mutate({
      mutation: gql`
        mutation ($configUuid: ID!, $assetUuids: [ID!], $force: Boolean) {
          syncDeviceConfig(configUuid: $configUuid, assetUuids: $assetUuids, force: $force) {
            statusCode
            message
            stamp
          }
        }
      `,
      variables: {
        configUuid,
        assetUuids,
        force
      }
    })
    .then(response => {
      return response.data.syncDeviceConfig;
    });
}

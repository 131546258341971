import { render, staticRenderFns } from "./MetricItem.vue?vue&type=template&id=61e957d6&"
import script from "./MetricItem.vue?vue&type=script&setup=true&lang=ts&"
export * from "./MetricItem.vue?vue&type=script&setup=true&lang=ts&"
import style0 from "./MetricItem.vue?vue&type=style&index=0&id=61e957d6&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VHover } from 'vuetify/lib/components/VHover';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
installComponents(component, {VHover,VIcon,VListItem,VListItemContent})

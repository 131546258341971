import { isNil, isObject } from "lodash";
import Vue, { unref } from "vue";
import VueI18n, { LocaleMessages, TranslateResult, Values } from "vue-i18n";
import { MaybeRef, StringOrTranslateKey, TranslateKey } from "@/types";

Vue.use(VueI18n);

function loadLocaleMessages(): LocaleMessages {
  const locales = require.context("../locales", true, /[A-Za-z0-9-_,\s]+\.json$/i);
  const messages: LocaleMessages = {};
  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1];
      messages[locale] = locales(key);
    }
  });
  return messages;
}

const numberFormats = {
  en: {
    integer: {
      style: "decimal",
      maximumFractionDigits: 0
    },
    decimal1dd: {
      style: "decimal",
      minimumFractionDigits: 1,
      maximumFractionDigits: 1
    },
    decimal2d: {
      style: "decimal",
      maximumFractionDigits: 2
    },
    decimal2dd: {
      style: "decimal",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }
  }
};

const i18n = new VueI18n({
  locale: process.env.VUE_APP_I18N_LOCALE || "en",
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || "en",
  messages: loadLocaleMessages(),
  numberFormats
});

export default i18n;

export function tFallbackKey(keys: string[]): string | undefined {
  return keys.find(k => i18n.te(k));
}

export function tFallback(keys: string[], values?: Values): TranslateResult {
  const foundKey = tFallbackKey(keys);
  if (!foundKey) return keys[0] ?? "missing-key";
  return i18n.t(foundKey, values);
}

type I18nNamespace = string | TranslateResult | null;

export function tNamespacedKey(
  i18Namespaces: MaybeRef<I18nNamespace | I18nNamespace[]>,
  key: string
): string | undefined {
  const namespaces = unref(i18Namespaces);
  const namespaceArray = Array.isArray(namespaces) ? namespaces : [namespaces];
  const filteredNamespaces = namespaceArray.filter(p => !isNil(p));
  const keys = filteredNamespaces.map(p => `${p}.${key}`);
  return tFallbackKey(keys);
}

export function tNamespaced(
  i18Namespaces: MaybeRef<I18nNamespace | I18nNamespace[]>,
  key: string,
  values?: Values
): TranslateResult {
  const foundKey = tNamespacedKey(i18Namespaces, key);
  if (!foundKey) return key ?? "missing-key";
  return i18n.t(foundKey, values);
}

export function isTranslateKey(key: StringOrTranslateKey): key is TranslateKey {
  return isObject(key) && "key" in key;
}

export function resolveTranslateKey(key: StringOrTranslateKey | null | undefined): string {
  if (isNil(key)) return "";

  if (isTranslateKey(key)) {
    return i18n.t(key.key, key.values).toString();
  } else {
    return String(key);
  }
}

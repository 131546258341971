import { MaybeRef, useCurrentElement } from "@vueuse/core";
import { onMounted, inject, provide, Ref, computed, onUnmounted, ref } from "vue";
import { ParentComponentContext } from "@/types";

let nextId = 0;

interface ChildComponentInfo<T> {
  id: number;
  element: HTMLElement | SVGElement;
  data: Ref<T>;
}

interface UseChildComponentsResult<ChildDataT = any> {
  childData: Ref<ChildDataT[]>;
  childDataById: Ref<[number, ChildDataT][]>;
}

export function useChildComponents<MyContextT = any, ChildDataT = any>(
  myContextData: MyContextT
): UseChildComponentsResult<ChildDataT> {
  const childrenMap: Map<number, ChildComponentInfo<ChildDataT>> = new Map();
  const orderedIds = ref<number[]>([]);

  function updateSortedChildren(): void {
    const children = Array.from(childrenMap.values());
    const orderedChildren = children.sort(documentPositionComparator);
    orderedIds.value = orderedChildren.map(child => child.id);
  }

  const myContext: ParentComponentContext<MyContextT, ChildDataT> = {
    context: myContextData,

    registerChild(id, element, data) {
      childrenMap.set(id, { id, element, data });
      updateSortedChildren();
    },

    unregisterChild(id) {
      childrenMap.delete(id);
      updateSortedChildren();
    }
  };
  provide("parentContext", myContext);

  onUnmounted(() => {
    childrenMap.clear();
    orderedIds.value = [];
  });

  // Computed based on both the array of child data, and each child data ref
  const sortedChildData = computed(() => orderedIds.value.map(id => childrenMap.get(id)!.data.value));
  const sortedChildDataById = computed<[number, ChildDataT][]>(() =>
    orderedIds.value.map(id => [id, childrenMap.get(id)!.data.value])
  );

  return {
    childData: sortedChildData,
    childDataById: sortedChildDataById
  };
}

interface UseParentComponentResult<ParentContextT = any, MyDataT = any> {
  context: ParentContextT;
  componentId: number;
  exposeDataToParent(data: MaybeRef<MyDataT>): void;
}

export function useOptionalParentComponent<ParentContextT = any, MyDataT = any>(): UseParentComponentResult<
  ParentContextT | null,
  MyDataT
  // eslint-disable-next-line indent
> {
  const parentContext = inject<ParentComponentContext<ParentContextT, MyDataT> | null>("parentContext", null);
  const componentId = nextId++;
  const containerElement = useCurrentElement();
  let myData: Ref<MyDataT> | null = null;

  const filteredElement = computed(() => {
    const element = containerElement.value;
    if (element && (element instanceof HTMLElement || element instanceof SVGElement)) {
      return element;
    }
    return null;
  });

  function exposeDataToParent(data: MaybeRef<MyDataT>): void {
    myData = ref(data) as Ref<MyDataT>;
  }

  onMounted(() => {
    if (parentContext && filteredElement.value && myData !== null) {
      parentContext.registerChild(componentId, filteredElement.value, myData);
    }
  });

  onUnmounted(() => {
    if (parentContext) {
      parentContext.unregisterChild(componentId);
    }
  });

  return {
    context: parentContext ? parentContext.context : null,
    componentId,
    exposeDataToParent
  };
}

export function useParentComponent<ParentContextT = any, MyDataT = any>(): UseParentComponentResult<
  ParentContextT,
  MyDataT
  // eslint-disable-next-line indent
> {
  const { context, componentId, exposeDataToParent } = useOptionalParentComponent<ParentContextT, MyDataT>();
  if (!context) throw new Error("Required parent component is missing");

  return {
    context,
    componentId,
    exposeDataToParent
  };
}

function documentPositionComparator<T>(aInfo: ChildComponentInfo<T>, bInfo: ChildComponentInfo<T>) {
  const a = aInfo.element;
  const b = bInfo.element;
  if (a === b) return 0;

  const position = a.compareDocumentPosition(b);

  if (position & Node.DOCUMENT_POSITION_FOLLOWING || position & Node.DOCUMENT_POSITION_CONTAINED_BY) {
    return -1;
  } else if (position & Node.DOCUMENT_POSITION_PRECEDING || position & Node.DOCUMENT_POSITION_CONTAINS) {
    return 1;
  } else {
    return 0;
  }
}

import { intersection, isEmpty, isEqual, isNil } from "lodash";
import { mergeAssetConfig } from "@/config/asset-config-reader";
import baseDeviceConfig from "@/config/base-device";
import { getValue, getFieldObject, getValues, fieldKey } from "@/config/form";
import { FieldDescriptorParams, Form, FormConfig, FormValues, PropFnArgs } from "@/types";
import { installRule } from "@/plugins/vee-validate/utils";
import { compareTimes } from "@/utils/date";
import { isBlank } from "@/utils/string";

export const PLANS = ["wake", "away", "home", "sleep"];
export const CONTROL_MODES = ["HEAT", "EMERGENCY HEAT", "COOL", "AUTO"];
export const FAN_MODES = ["AUTO", "ON", "CIRCULATE"];
export const EMPTY_TIME = "0:00";
export const MAX_BANKS = 16;
export const SCHEDULE_TIME_FIELDS = ["schedule_enabled", "schedule_time", "schedule_days"];

export const SCHEDULE_FIELDS = [
  "schedule_fan_mode",
  "schedule_target_temp",
  "schedule_tolerance_temp",
  ...SCHEDULE_TIME_FIELDS
];

type BankDays = string[] | undefined;
type BankTimeDays = [string | undefined, BankDays];

installRule("tstat_unique_time", {
  validate(value, params) {
    const { days: thisBankDays, otherBanks } = params as { days: BankDays; otherBanks: BankTimeDays[] };
    if (isBlank(value) || isEmpty(thisBankDays) || isEmpty(otherBanks)) return true;

    return otherBanks.every(([time, days]) => {
      if (isEmpty(days)) return true;
      const timesDiffer = compareTimes(value, time ?? "") !== 0;
      const daysDiffer = intersection(thisBankDays, days).length === 0;
      return timesDiffer || daysDiffer;
    });
  },
  params: ["days", "otherBanks"]
});

function bankDays({ model, params }: PropFnArgs): string[] {
  const descriptor = { name: "schedule_days", params };
  return getValue(model.config, model, descriptor) ?? [];
}

function otherBanks({ model, params }: PropFnArgs): BankTimeDays[] {
  const banks: BankTimeDays[] = [];

  for (const plan of PLANS) {
    const planIndex = planToIndex(plan);
    const banksCount = banksLength(model.config, model, planIndex);

    for (let bank = 0; bank < banksCount; bank++) {
      const bankParams = [planIndex, bank];
      if (isEqual(params, bankParams)) continue;

      const time = getValue(model.config, model, { name: "schedule_time", params: bankParams }) as string | null;
      const days = getValue(model.config, model, { name: "schedule_days", params: bankParams }) as string[] | null;
      banks.push([time ?? undefined, days ?? []]);
    }
  }

  return banks;
}

export function banksLength(
  config: FormConfig,
  form: Form,
  planIndex: number,
  notEmptyIfNew = true,
  notEmptyIfDeleted = false
): number {
  for (let i = MAX_BANKS; i >= 1; i--) {
    if (!isBankEmpty(config, form, [planIndex, i - 1], notEmptyIfNew, notEmptyIfDeleted)) return i;
  }

  return 0;
}

export function isBankEmpty(
  config: FormConfig,
  form: Form,
  params: FieldDescriptorParams,
  notEmptyIfNew = true,
  notEmptyIfDeleted = false
): boolean {
  const enabledField = getFieldObject(config, form, { name: "schedule_enabled", params });
  const timeField = getFieldObject(config, form, { name: "schedule_time", params });
  const daysField = getFieldObject(config, form, { name: "schedule_days", params });

  if (notEmptyIfNew && (enabledField.new || timeField.new || daysField.new)) return false;
  if (notEmptyIfDeleted && (enabledField.deleted || timeField.deleted || daysField.deleted)) return false;

  const enabled = enabledField.value as boolean | null;
  const time = timeField.value as string | null;
  const days = daysField.value as string[] | null;

  return (isNil(enabled) || enabled === false) && (isEmpty(time) || time === EMPTY_TIME) && isEmpty(days);
}

function shouldValidateBank(config: FormConfig, form: Form, params: FieldDescriptorParams): boolean {
  return !isBankEmpty(config, form, params, false);
}

export function planToIndex(plan: string): number {
  return PLANS.indexOf(plan);
}

export function controlModeToIndex(mode: string): number {
  const index = CONTROL_MODES.indexOf(mode);
  return index === -1 ? 0 : index;
}

export function targetTemperatureParams(model: Form): FieldDescriptorParams {
  const mode = getValue(model.config, model, "temperature_control_mode");
  return [controlModeToIndex(mode)];
}

installRule("tstat_ob_conditions", {
  validate(value, params) {
    const {
      fields: { y1, y2, g_gh: gGh, pek }
    } = params as { fields: ReturnType<typeof installationFields> };
    return !value || ((!!y1 || !!y2) && !!gGh) || !!pek;
  },
  params: ["fields"]
});

installRule("tstat_pek_conditions", {
  validate(value, params) {
    const {
      fields: { y1, g_gh: gGh }
    } = params as { fields: ReturnType<typeof installationFields> };
    return !value || (!y1 && !gGh);
  },
  params: ["fields"]
});

installRule("tstat_y1_conditions", {
  validate(value, params) {
    const {
      fields: { g_gh: gGh }
    } = params as { fields: ReturnType<typeof installationFields> };
    return !value || !!gGh;
  },
  params: ["fields"]
});

installRule("tstat_y2_conditions", {
  validate(value, params) {
    const {
      fields: { g_gh: gGh }
    } = params as { fields: ReturnType<typeof installationFields> };
    return value !== "Y2" || !!gGh;
  },
  params: ["fields"]
});

function installationFields({ model }: PropFnArgs) {
  return {
    y1: getValue(model.config, model, "installation_y1") as boolean | null,
    y2: (getValue(model.config, model, "installation_y2_gl") === "Y2") as boolean,
    g_gh: getValue(model.config, model, "installation_g_gh") as boolean | null,
    pek: getValue(model.config, model, "installation_pek") as boolean | null
  };
}

export function getScheduleFields(form: Form, useMaxBanks = false): FormValues {
  let fields: FormValues = {};
  const getFieldOptions = { deconvertValue: true, skipPending: true };

  for (const plan of PLANS) {
    const planIndex = planToIndex(plan);
    const planParams = { params: [planIndex] };

    for (const name of ["schedule_fan_mode", "schedule_target_temp", "schedule_tolerance_temp"]) {
      const descriptor = { name, ...planParams };
      fields = { ...fields, ...getValues(form.config, form, [descriptor], getFieldOptions) };
    }

    const banksCount = useMaxBanks ? MAX_BANKS : banksLength(form.config, form, planIndex, false, true);

    for (let bank = 0; bank < banksCount; bank++) {
      const bankParams = { params: [planIndex, bank] };

      const timeField = getFieldObject(form.config, form, {
        name: "schedule_time",
        ...bankParams
      });
      const daysField = getFieldObject(form.config, form, {
        name: "schedule_days",
        ...bankParams
      });
      const enabledField = getFieldObject(form.config, form, {
        name: "schedule_enabled",
        ...bankParams
      });

      const time = timeField.value as string | null;
      const days = daysField.value as string[] | null;
      const enabled = enabledField.value as boolean | null;

      if (isNil(time) || time === EMPTY_TIME || timeField.deleted) {
        fields[fieldKey(timeField)] = EMPTY_TIME;
        fields[fieldKey(daysField)] = [];
        fields[fieldKey(enabledField)] = false;
      } else {
        fields[fieldKey(timeField)] = time;
        fields[fieldKey(daysField)] = days ?? [];
        fields[fieldKey(enabledField)] = enabled ?? false;
      }
    }
  }

  return fields;
}

const config = mergeAssetConfig(baseDeviceConfig, {
  i18nNamespace: "tstat",
  image: require("@/tstat/images/tstat.svg"),
  components: {
    AssetDashboardTab: () => import("@/tstat/components/DeviceDashboardTab.vue"),
    AssetConfigTab: () => import("@/tstat/components/DeviceConfigTab.vue"),
    AssetControlPanel: () => import("@/tstat/components/TstatControlPanel.vue"),
    AssetInspectorStatus: {
      component: () => import("@/tstat/components/AssetInspectorStatus.vue"),
      props: {
        itemProperties: ["ambient_temperature"]
      }
    }
  },
  categoryProperties: {
    temperature: { property: "ambient_temperature" }
  },
  properties: {
    // The form sets this field if the device has an assigned schedule
    assigned_schedule: {
      dataType: "boolean"
    },
    ambient_temperature: {
      unit: "degrees_c",
      aggregation: "AVERAGE",
      fitBounds: true,
      unitSelectorFn: () => "degrees_f",
      altUnits: {
        degrees_f: {
          graphFormat: "decimal1dd"
        }
      },
      comparable: true
    },
    system_status: {
      dataType: "boolean",
      category: "settings",
      labelKey: "switch_label"
    },
    temperature_control_mode: {
      dataType: "string",
      category: "settings",
      key: "temperature_control_mode__mode",
      options: CONTROL_MODES,
      graphPointConfig: {
        HEAT: { y: 100, color: "#E57373" },
        "EMERGENCY HEAT": { y: 100, color: "#E57373" },
        COOL: { y: 100, color: "#01579B" },
        AUTO: { y: 100, color: "#B39DDB" }
      },
      fieldConfig: {
        displayType: "select",
        rules: { required: true }
      }
    },
    temperature_control_status: {
      dataType: "string",
      category: "properties",
      options: [
        "STANDBY",
        "1-STAGE HEATING",
        "2-STAGE HEATING",
        "3-STAGE HEATING",
        "4-STAGE HEATING",
        "EMERGENCY HEATING",
        "1-STAGE COOLING",
        "2-STAGE COOLING"
      ],
      graphPointConfig: {
        STANDBY: { y: 10, color: "#CFD8DC" },
        "1-STAGE HEATING": { y: 50, color: "#E57373" },
        "2-STAGE HEATING": { y: 100, color: "#E57373" },
        "3-STAGE HEATING": { y: 100, color: "#E57373" },
        "4-STAGE HEATING": { y: 100, color: "#E57373" },
        "EMERGENCY HEATING": { y: 100, color: "#E57373" },
        "1-STAGE COOLING": { y: 50, color: "#01579B" },
        "2-STAGE COOLING": { y: 100, color: "#01579B" }
      }
    },
    fan_mode: {
      dataType: "string",
      category: "settings",
      options: FAN_MODES,
      fieldConfig: {
        displayType: "select",
        rules: { required: true }
      }
    },
    fan_control_status: {
      dataType: "string",
      category: "properties",
      options: ["OFF", "ON", "LOW", "HIGH"],
      graphPointConfig: {
        OFF: { y: 0, color: "#CFD8DC" },
        ON: { y: 100, color: "#90A4AE" },
        LOW: { y: 50, color: "#90A4AE" },
        HIGH: { y: 100, color: "#90A4AE" }
      }
    },
    historical_target_temperature: {
      dataType: "number",
      category: "settings",
      key: "target_temperature",
      format: "integer",
      unit: "degrees_c",
      aggregation: "AVERAGE",
      fitBounds: true,
      unitSelectorFn: () => "degrees_f",
      altUnits: {
        degrees_f: {
          graphFormat: "integer"
        }
      },
      comparable: true
    },
    target_temperature: {
      dataType: "number",
      dimensions: 1,
      category: "settings",
      key: "temperature_control_mode__point",
      dependsOnFields: ["temperature_control_mode"],
      format: "integer",
      unit: "degrees_c",
      unitSelectorFn: () => "degrees_f",
      paramsSelectorFn: ({ model }) => targetTemperatureParams(model),
      fieldConfig: {
        rules: {
          required: true,
          between: { min: 32, max: 91 },
          integer: true
        }
      }
    },
    temperature_tolerance: {
      dataType: "number",
      category: "settings",
      key: "temperature_tolerance__point",
      format: "integer",
      unit: "degrees_c_relative",
      unitSelectorFn: () => "degrees_f_relative",
      fieldConfig: {
        rules: {
          required: true,
          between: { min: 1, max: 9 },
          integer: true
        }
      }
    },
    temperature_control_tolerance: {
      dataType: "number",
      category: "settings",
      key: "temperature_tolerance__control",
      dependsOnFields: ["temperature_control_mode"],
      format: "integer",
      unit: "degrees_c_relative",
      unitSelectorFn: () => "degrees_f_relative",
      fieldConfig: {
        disabled: ({ model }) => getValue(model.config, model, "temperature_control_mode") !== "AUTO",
        rules: {
          required: true,
          between: { min: 1, max: 18 },
          integer: true
        }
      }
    },
    schedule_fan_mode: {
      dataType: "string",
      dimensions: 1,
      category: "settings",
      key: "schedule_content__fan_mode",
      options: FAN_MODES,
      fieldConfig: {
        displayType: "select",
        disabled: ({ model }) => getValue(model.config, model, "assigned_schedule"),
        rules: { required: true }
      }
    },
    schedule_target_temp: {
      dataType: "number",
      dimensions: 1,
      category: "settings",
      key: "schedule_content__target_temp",
      format: "integer",
      unit: "degrees_c",
      unitSelectorFn: () => "degrees_f",
      fieldConfig: {
        disabled: ({ model }) => getValue(model.config, model, "assigned_schedule"),
        rules: {
          required: true,
          between: { min: 32, max: 91 },
          integer: true
        }
      }
    },
    schedule_tolerance_temp: {
      dataType: "number",
      dimensions: 1,
      category: "settings",
      key: "schedule_content__tolerance_temp",
      format: "integer",
      unit: "degrees_c_relative",
      unitSelectorFn: () => "degrees_f_relative",
      fieldConfig: {
        disabled: ({ model }) => getValue(model.config, model, "assigned_schedule"),
        rules: {
          required: true,
          between: { min: 1, max: 18 },
          integer: true
        }
      }
    },
    schedule_enabled: {
      dataType: "boolean",
      dimensions: 2,
      category: "settings",
      key: "schedule_time__enabled",
      labelKey: "switch_label",
      fieldConfig: {
        disabled: ({ model }) => getValue(model.config, model, "assigned_schedule")
      }
    },
    schedule_time: {
      dataType: "time",
      dimensions: 2,
      category: "settings",
      key: "schedule_time__time",
      fieldConfig: {
        disabled: ({ model }) => getValue(model.config, model, "assigned_schedule"),
        rules: propArgs => ({
          required: shouldValidateBank(propArgs.model.config, propArgs.model, propArgs.params),
          tstat_unique_time: {
            days: bankDays(propArgs),
            otherBanks: otherBanks(propArgs)
          }
        })
      }
    },
    schedule_days: {
      dataType: "string",
      dimensions: 2,
      category: "settings",
      key: "schedule_time__days",
      options: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
      fieldConfig: {
        displayType: "select",
        disabled: ({ model }) => getValue(model.config, model, "assigned_schedule"),
        multiple: true
      }
    },
    time_zone: {
      dataType: "number",
      category: "settings",
      fieldConfig: {
        displayType: "select",
        rules: { required: true }
      }
    },
    dst_enabled: {
      dataType: "boolean",
      category: "settings",
      key: "dst__enabled",
      labelKey: "switch_label"
    },
    dst_start_month: {
      dataType: "string",
      category: "settings",
      key: "dst__start_month",
      fieldConfig: {
        displayType: "select",
        disabled: ({ model }) => !getValue(model.config, model, "dst_enabled"),
        rules: { required: true }
      }
    },
    dst_start_week: {
      dataType: "number",
      category: "settings",
      key: "dst__start_week",
      fieldConfig: {
        disabled: ({ model }) => !getValue(model.config, model, "dst_enabled"),
        rules: {
          required: true,
          between: { min: 1, max: 5 },
          integer: true
        }
      }
    },
    dst_start_day: {
      dataType: "string",
      category: "settings",
      key: "dst__start_day",
      fieldConfig: {
        displayType: "select",
        disabled: ({ model }) => !getValue(model.config, model, "dst_enabled"),
        rules: { required: true }
      }
    },
    dst_start_hour: {
      dataType: "number",
      category: "settings",
      key: "dst__start_hour",
      fieldConfig: {
        disabled: ({ model }) => !getValue(model.config, model, "dst_enabled"),
        rules: {
          required: true,
          between: { min: 0, max: 23 },
          integer: true
        }
      }
    },
    dst_end_month: {
      dataType: "string",
      category: "settings",
      key: "dst__end_month",
      fieldConfig: {
        displayType: "select",
        disabled: ({ model }) => !getValue(model.config, model, "dst_enabled"),
        rules: { required: true }
      }
    },
    dst_end_week: {
      dataType: "number",
      category: "settings",
      key: "dst__end_week",
      fieldConfig: {
        disabled: ({ model }) => !getValue(model.config, model, "dst_enabled"),
        rules: {
          required: true,
          between: { min: 1, max: 5 },
          integer: true
        }
      }
    },
    dst_end_day: {
      dataType: "string",
      category: "settings",
      key: "dst__end_day",
      fieldConfig: {
        displayType: "select",
        disabled: ({ model }) => !getValue(model.config, model, "dst_enabled"),
        rules: { required: true }
      }
    },
    dst_end_hour: {
      dataType: "number",
      category: "settings",
      key: "dst__end_hour",
      fieldConfig: {
        disabled: ({ model }) => !getValue(model.config, model, "dst_enabled"),
        rules: {
          required: true,
          between: { min: 0, max: 23 },
          integer: true
        }
      }
    },
    child_lock_system: {
      dataType: "boolean",
      category: "settings",
      key: "child_lock__system",
      labelKey: "switch_label"
    },
    child_lock_temp_up: {
      dataType: "boolean",
      category: "settings",
      key: "child_lock__temp_up",
      labelKey: "switch_label"
    },
    child_lock_temp_down: {
      dataType: "boolean",
      category: "settings",
      key: "child_lock__temp_down",
      labelKey: "switch_label"
    },
    child_lock_fan_mode: {
      dataType: "boolean",
      category: "settings",
      key: "child_lock__fan_mode",
      labelKey: "switch_label"
    },
    child_lock_temp_control_mode: {
      dataType: "boolean",
      category: "settings",
      key: "child_lock__temp_control_mode",
      labelKey: "switch_label"
    },
    child_lock_reset: {
      dataType: "boolean",
      category: "settings",
      key: "child_lock__reset",
      labelKey: "switch_label"
    },
    installation_w1: {
      dataType: "boolean",
      category: "settings",
      key: "installation_wiring__w1",
      defaultValue: false,
      fieldConfig: {
        displayType: "buttons"
      }
    },
    installation_w2_aux: {
      dataType: "string",
      category: "settings",
      key: "installation_wiring__w2_aux",
      options: ["W2", "AUX"],
      defaultValue: "DISABLE",
      fieldConfig: {
        displayType: "buttons",
        deselectedValue: "DISABLE"
      }
    },
    installation_y1: {
      dataType: "boolean",
      category: "settings",
      key: "installation_wiring__y1",
      defaultValue: false,
      fieldConfig: {
        displayType: "buttons",
        rules: params => ({
          tstat_y1_conditions: installationFields(params)
        })
      }
    },
    installation_y2_gl: {
      dataType: "string",
      category: "settings",
      key: "installation_wiring__y2_gl",
      options: ["Y2", "GL"],
      defaultValue: "DISABLE",
      fieldConfig: {
        displayType: "buttons",
        deselectedValue: "DISABLE",
        rules: params => ({
          tstat_y2_conditions: installationFields(params)
        })
      }
    },
    installation_g_gh: {
      dataType: "boolean",
      category: "settings",
      key: "installation_wiring__g_gh",
      defaultValue: false,
      fieldConfig: {
        displayType: "buttons"
      }
    },
    installation_ob: {
      dataType: "boolean",
      category: "settings",
      key: "installation_wiring__ob_enable",
      defaultValue: false,
      fieldConfig: {
        displayType: "buttons",
        rules: params => ({
          tstat_ob_conditions: installationFields(params)
        })
      }
    },
    installation_e: {
      dataType: "boolean",
      category: "settings",
      key: "installation_wiring__e",
      defaultValue: false,
      fieldConfig: {
        displayType: "buttons"
      }
    },
    installation_cl_cn: {
      dataType: "boolean",
      category: "settings",
      key: "installation_wiring__cl_cn",
      defaultValue: false,
      fieldConfig: {
        displayType: "buttons"
      }
    },
    installation_pek: {
      dataType: "boolean",
      category: "settings",
      key: "installation_wiring__pek",
      defaultValue: false,
      fieldConfig: {
        displayType: "buttons",
        rules: params => ({
          tstat_pek_conditions: installationFields(params)
        })
      }
    },
    installation_ob_function: {
      dataType: "string",
      category: "settings",
      key: "installation_wiring__ob_function",
      options: ["ON_COOL", "ON_HEAT"],
      fieldConfig: {
        displayType: "select",
        disabled: ({ model }) => !getValue(model.config, model, "installation_ob"),
        rules: {
          required: true
        }
      }
    }
  }
});

export default config;

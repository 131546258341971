import gql from "graphql-tag";
import { apolloClient } from "@/plugins/vue-apollo";

export default function (configUuid: string, assetUuids: string[]): Promise<boolean> {
  return apolloClient
    .mutate({
      mutation: gql`
        mutation ($configUuid: ID!, $assetUuids: [ID!]!) {
          unassignDeviceConfig(configUuid: $configUuid, assetUuids: $assetUuids)
        }
      `,
      variables: {
        configUuid,
        assetUuids
      }
    })
    .then(() => {
      return true;
    });
}

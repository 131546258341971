import gql from "graphql-tag";
import { apolloClient } from "@/plugins/vue-apollo";

export default function (configUuid: string): Promise<boolean> {
  return apolloClient
    .mutate({
      mutation: gql`
        mutation ($configUuid: ID!) {
          deleteDeviceConfig(configUuid: $configUuid)
        }
      `,
      variables: {
        configUuid
      }
    })
    .then(() => {
      return true;
    });
}

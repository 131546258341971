import { render, staticRenderFns } from "./MetricItemTitle.vue?vue&type=template&id=d0ac8f64&scoped=true&"
import script from "./MetricItemTitle.vue?vue&type=script&setup=true&lang=ts&"
export * from "./MetricItemTitle.vue?vue&type=script&setup=true&lang=ts&"
import style0 from "./MetricItemTitle.vue?vue&type=style&index=0&id=d0ac8f64&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d0ac8f64",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
installComponents(component, {VBtn,VIcon,VListItemTitle,VProgressCircular})

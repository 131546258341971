import Vue, { Ref, unref } from "vue";
import {
  Form,
  FormConfig,
  MaybeRef,
  SubmitSelection,
  FormValues,
  FieldDescriptor,
  FormSubmitServerParams,
  AssetConfig,
  PropertyContainer,
  GqlBuilding,
  DeviceConfig,
  DeviceConfigType,
  GqlProperty
} from "@/types";
import { buildAssetFormConfig, copySinglePropertyToForm } from "@/config/asset-form";
import { FormHandler, FormHandlerParams } from "./use-form";
import { createProperties, getPropertyConfig } from "@/utils/properties";
import { expandDescriptorToIndexes, getIndexedField } from "@/utils/indexed-field";
import { mergeFormConfig } from "@/config/form";
import createDeviceConfig from "@/gql/create-device-config";
import updateDeviceConfig from "@/gql/update-device-config";
import syncDeviceConfig from "@/gql/sync-device-config";
import { mapValues } from "lodash";

export interface SavedConfigFormHandlerParams extends FormHandlerParams {
  building: MaybeRef<GqlBuilding>;
  configType: MaybeRef<DeviceConfigType>;
  i18nNamespace: MaybeRef<string>;
  assetConfig: MaybeRef<AssetConfig>;
  properties: FieldDescriptor[];
  savedConfig: MaybeRef<DeviceConfig>;
}

export class SavedConfigFormHandler extends FormHandler {
  building: MaybeRef<GqlBuilding>;
  configType: MaybeRef<DeviceConfigType>;
  i18nNamespace: MaybeRef<string>;
  assetConfig: MaybeRef<AssetConfig>;
  properties: FieldDescriptor[];
  savedConfig: MaybeRef<DeviceConfig>;

  constructor(form: Ref<Form>, params: SavedConfigFormHandlerParams) {
    const propertyNames = [...params.properties, "config_name"];
    super(form, { descriptors: propertyNames });
    this.building = params.building;
    this.configType = params.configType;
    this.i18nNamespace = params.i18nNamespace;
    this.assetConfig = params.assetConfig;
    this.properties = params.properties;
    this.savedConfig = params.savedConfig;
  }

  getFormConfig(): Partial<FormConfig> {
    const assetFormConfig = buildAssetFormConfig(unref(this.assetConfig));
    return mergeFormConfig(assetFormConfig, {
      i18nNamespace: unref(this.i18nNamespace),
      fields: {
        config_name: {
          dataType: "string",
          rules: { required: true }
        }
      }
    });
  }

  populateForm(force = false): void {
    const assetConfig = unref(this.assetConfig);
    const savedConfig = unref(this.savedConfig);

    Vue.set(this.form.value, "config_name", savedConfig.name);

    const propertyContainer = {};
    const settingsAsProperties = convertSettingsToProperties(savedConfig.settings);
    createProperties(assetConfig, propertyContainer, settingsAsProperties);

    for (const property of this.properties) {
      copyPropertyToForm(assetConfig, propertyContainer, this.form.value, property, force);
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  getValuesToSubmit(submitSelection: SubmitSelection): FormValues {
    return {
      config_name: this.form.value.config_name
    };
  }

  async submit(
    values: FormValues,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    submitSelection: SubmitSelection,
    serverParams: FormSubmitServerParams
  ): Promise<boolean> {
    if (!unref(this.savedConfig).configUuid) {
      return this.create(values, submitSelection, serverParams);
    } else {
      return this.update(values, submitSelection, serverParams);
    }
  }

  async create(
    values: FormValues,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    submitSelection: SubmitSelection,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    serverParams: FormSubmitServerParams
  ): Promise<boolean> {
    let success = false;

    const name = values.config_name;
    delete values.config_name;

    const config: Omit<DeviceConfig, "configUuid"> = {
      buildingUuid: unref(this.building).buildingUuid,
      configType: unref(this.configType),
      name,
      settings: values
    };

    await createDeviceConfig(config)
      .then(() => {
        success = true;
      })
      .catch(() => {
        success = false;
      });

    return success;
  }

  async update(
    values: FormValues,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    submitSelection: SubmitSelection,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    serverParams: FormSubmitServerParams
  ): Promise<boolean> {
    let success = false;

    const name = values.config_name;
    delete values.config_name;

    const config: DeviceConfig = {
      configUuid: unref(this.savedConfig).configUuid,
      buildingUuid: unref(this.building).buildingUuid,
      configType: unref(this.configType),
      name,
      settings: values
    };

    await updateDeviceConfig(config)
      .then(() => {
        return syncDeviceConfig(config.configUuid);
      })
      .then(() => {
        success = true;
      })
      .catch(() => {
        success = false;
      });

    return success;
  }
}

function convertSettingsToProperties(settings: Record<string, any>): Record<string, GqlProperty> {
  return mapValues(settings, value => {
    return {
      value,
      stamp: null,
      alias_name: null,
      update_stamp: null,
      state: null,
      pending: false,
      state_info: {}
    };
  });
}

function copyPropertyToForm(
  assetConfig: AssetConfig,
  properties: PropertyContainer,
  form: Form,
  descriptor: FieldDescriptor,
  force = false
): void {
  const propertyConfig = getPropertyConfig(assetConfig, descriptor);
  const descriptors = expandDescriptorToIndexes(properties, descriptor, propertyConfig.dimensions);

  for (const expansion of descriptors) {
    const property = getIndexedField(properties, expansion);
    if (property) {
      copySinglePropertyToForm(property, form, force);
    }
  }
}

import { ref, Ref, unref } from "vue";
import { useQuery } from "@vue/apollo-composable";
import gql from "graphql-tag";
import { GqlGroup, MaybeRef } from "@/types";

export interface UseAccountBuildingsOptions {
  enabled?: MaybeRef<boolean>;
}

export interface UseAccountBuildingsResult {
  groups: Ref<GqlGroup[] | undefined>;
  loadingError: Ref<boolean>;
  refetch: () => void;
  loading: Ref<boolean>;
}

export function useAccountBuildings(
  accountUuid: MaybeRef<string | null>,
  { enabled = true }: UseAccountBuildingsOptions = {}
): UseAccountBuildingsResult {
  const groups = ref<GqlGroup[] | undefined>();
  const loadingError = ref(false);

  const { onResult, onError, refetch, loading } = useQuery(
    gql`
      query AccountQuery($accountUuid: ID!) {
        account(accountUuid: $accountUuid) {
          groups {
            groupUuid
            name
            buildings {
              buildingUuid
              accountUuid
              name
              fullAddress
              online
              timeZone
              gpsCoordinates {
                lat
                lng
              }
            }
          }
        }
      }
    `,
    {
      accountUuid
    },
    () => ({
      enabled: unref(enabled) && unref(accountUuid) !== null,
      fetchPolicy: "no-cache",
      notifyOnNetworkStatusChange: true
    })
  );

  onError(() => {
    loadingError.value = true;
  });

  onResult(queryResult => {
    if (queryResult.data) {
      const gqlGroups: GqlGroup[] = queryResult.data.account.groups;
      groups.value = gqlGroups;
    }
  });

  return { groups, loadingError, refetch, loading };
}

import { GraphSeriesConfig } from "@/types";

// Graphs

export const MAX_SERIES = 8;
export const DEFAULT_GRAPH_SERIES_CONFIG: GraphSeriesConfig = {
  hideValueUnit: false,
  comparable: false,
  aggregation: "MAXIMUM",
  stepGraph: false,
  fitBounds: false
};
export const DISABLED_SERIES_COLOR = "#aaaaaa";
export const SERIES_COLORS = [
  "#0076BC",
  "#880088",
  "#ED561B",
  "#9575CD",
  "#24CBE5",
  "#64E572",
  "#FF9655",
  "#FFF263",
  "#6AF9C4",
  "#50B432"
];

// Properties

export const DEFAULT_UNIT = "default_unit";
export const DEFAULT_VOLTAGE = 120;
export const DEFAULT_POWER_FACTOR = 1;
export const DEFAULT_STALE_DATA_DURATION = 75;

import gql from "graphql-tag";
import { apolloClient } from "@/plugins/vue-apollo";
import store from "@/store";

export default function (uuids: string[], newLocationType: string, newLocationUuid: string): Promise<any> {
  return apolloClient
    .mutate({
      mutation: gql`
        mutation ($accountUuid: ID!, $uuids: [ID!]!, $newLocationType: LocationType!, $newLocationUuid: ID!) {
          copyDocuments(
            accountUuid: $accountUuid
            uuids: $uuids
            newLocationType: $newLocationType
            newLocationUuid: $newLocationUuid
          ) {
            message
            statusCode
          }
        }
      `,
      variables: {
        accountUuid: store.getters.contextAccountUuid,
        uuids: uuids,
        newLocationType: newLocationType,
        newLocationUuid: newLocationUuid
      }
    })
    .then(response => {
      return response.data.copyDocuments;
    });
}
